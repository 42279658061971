import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import CatPostLinkCSS from './catpost-link.module.css'

const CatPostLink = ({ post }) => (
  <li className={CatPostLinkCSS.box}>
    <Link activeClassName="active" to={post.frontmatter.path}>
      <Img
        className={CatPostLinkCSS.imgcat}
        imgStyle={{ objectFit: 'cover' }}
        fluid={post.frontmatter.featured.childImageSharp.fluid}
      />
      <div className={CatPostLinkCSS.name}>{post.frontmatter.title}</div>
    </Link>
  </li>
)

export default CatPostLink
