import React from 'react'
import CatMenuCSS from './cat-menu.module.css'

const CatMenu = ({ children, catname }) => (
  <ul className={CatMenuCSS.box}>
    {children}
    <li className={CatMenuCSS.menu}>
      <span className={`${CatMenuCSS.catname} catpagename`}>{catname}</span>
      <div className={`${CatMenuCSS.catpagebar} catpagebar`} />
    </li>
  </ul>
)

export default CatMenu
